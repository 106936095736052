<script setup>

import { defineProps } from 'vue'
import { Handle, Position } from '@vue-flow/core'
import { usePipelineStore } from '@/stores/pipeline'

const props = defineProps(['id', 'label', 'data'])
const pipelineStore = usePipelineStore()
const [targetHandles, sourceHandles] = pipelineStore.handlesForTask(props.id)

const templateToclass = (template) => {
  switch (template) {
    case 'default':
      return 'bg-default'
    case 'isolated':
      return 'bg-isolated'
    default:
      return 'bg-default'
  }
}

</script>

<template>
  <Handle v-for="(item, index) in targetHandles"
    :key="index"
    :id="item.id"
    :position="Position.Top"
    :style="`margin-left: ${index * 10}px`"
    :class="templateToclass(item.data?.template)"
    :title="item.data?.title"
    :type="item.type"
   />

  <div>
    {{ label }}
  </div>
  <div>{{ data.slug }}</div>

  <Handle v-for="(item, index) in sourceHandles"
    :key="index"
    :id="item.id"
    :position="Position.Bottom"
    :style="`margin-left: ${index * 10}px`"
    :class="templateToclass(item.data?.template)"
    :title="item.data?.title"
    :type="item.type"
   />
</template>

<style>
  .bg-default {
    background-color: black !important;
  }
  .bg-isolated {
    border-color: black !important;
    background-color: white !important;
  }
</style>
